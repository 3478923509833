import Vue from 'vue'
import App from './App.vue'
import router from './router'
import guard from './guard'
import Vant from "vant";

import "vant/lib/index.css";

Vue.config.productionTip = false
Vue.use(Vant); //全局定义组件

new Vue({
  router, //router === this.$router
  guard,
  render: h => h(App),
}).$mount('#app')