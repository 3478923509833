import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history', //路由模式 
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        title: '首页'
      },
    },
    {
      path: '/coin',
      name: 'coin',
      component: () => import('@/views/Coin.vue'),
      meta: {
        title: '企业文化币'
      },
    },
    {
      path: '/coin/detail/:id',
      name: 'coinDetail',
      component: () => import('@/views/CoinDetail.vue'),
      meta: {
        title: '文化币详情'
      },
    },
    {
      path: '/score',
      name: 'score',
      component: () => import('@/views/Score.vue'),
      meta: {
        title: '企业价值观'
      }
    },
    {
      path: '/score/detail/:id',
      name: 'scoreDetail',
      component: () => import('@/views/ScoreDetail.vue'),
      meta: {
        title: '考核详情'
      },
    }
  ]
})

export default router