import router from './router'

const guard = router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth) { //登录验证
    if (localStorage.getItem('token')) {
      next()
    } else {
      // next('/login')
      next({
        path: '/login',
        //被拦截到的路由
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

export default guard